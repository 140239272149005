import type { MetaDescriptor } from "@remix-run/react/dist/routeModules"
import type { SerializeFrom } from "@remix-run/node"
import { stripHtml } from "string-strip-html"

interface Metadata {
  title: string
  description: string
  image: string
  openGraphSiteName?: string
  openGraphType?: string
  openGraphUrl?: string
  twitterCard?: string
  twitterSite?: string
}

const defaultMetadata: Metadata = {
  title: "Renewing Your Mind with R.C. Sproul | Daily Christian Teaching",
  description: [
    "Your daily destination for trustworthy Christian teaching and in-depth Bible study.",
  ].join(" "),
  image: "https://renewingyourmind.org/images/social.jpg",
  openGraphSiteName: "Renewing Your Mind",
  openGraphType: "website",
  openGraphUrl: "https://renewingyourmind.org",
  twitterCard: "summary_large_image",
  twitterSite: "@RYMRadio",
}

export const metadataMapper = (
  metadataArg?: SerializeFrom<Partial<Metadata>>,
): Array<MetaDescriptor> => {
  const metadata: Metadata = {} as Metadata

  let metadataKey: keyof Metadata

  for (metadataKey in defaultMetadata) {
    const defaultMetadataValue = defaultMetadata[metadataKey]

    const metadataValue = metadataArg?.[metadataKey] || defaultMetadataValue

    if (metadataValue) metadata[metadataKey] = metadataValue as string // ewwwwwwwww
  }

  const {
    title,
    description: htmlDescription,
    image,
    openGraphSiteName,
    openGraphType,
    openGraphUrl,
    twitterCard,
    twitterSite,
  } = metadata

  const { result: description } = stripHtml(htmlDescription)

  return [
    { charSet: "utf-8" },
    { name: "referrer", content: "origin" },
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1,maximum-scale=5",
    },
    { title },
    { name: "description", content: description },
    { name: "revisit-after", content: "1 days" },
    { property: "og:type", content: openGraphType },
    { property: "og:url", content: openGraphUrl },
    { property: "og:site_name", content: openGraphSiteName },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:image", content: image },
    { name: "twitter:site", content: twitterSite },
    { name: "twitter:card", content: twitterCard },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { name: "twitter:url", content: openGraphUrl },
    { name: "twitter:image:src", content: image },
    {
      property: "fb:app_id",
      content: "144056502621245",
    },
    {
      name: "detectify-verification",
      content: "cda3fe890e7958dd08f5009a4b655675",
    },
  ]
}
